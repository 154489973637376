<template>
  <section>
    <v-list two-line v-if="coordinate.length">
      <template v-for="(coordinata, index) in coordinate">
        <v-list-tile
          @click.stop="openCoordinateDialog(coordinata)"
          :key="'tile-'+coordinata._id">
          <v-list-tile-content>
            <v-list-tile-title v-text="coordinata.descrizione"></v-list-tile-title>
            <v-list-tile-sub-title>{{ coordinata.iban }}</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon @click="eliminaCoordinata = true; coordinata = coordinata" color="error">delete</v-icon>
          </v-list-tile-action>
        </v-list-tile>
        <v-divider :key="coordinata._id" v-if="index < coordinate.length - 1" />
      </template>
    </v-list>
    <v-alert
      outline=""
      color="info"
      value="true"
      v-if="!coordinate.length">
      Nessuna coordinata trovata
    </v-alert>
    <!-- modale coordinata -->
    <v-dialog
      v-model="showCoordinata"
      transition="dialog-bottom-transition"
      :overlay="false"
      max-width="900">
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="showCoordinata=false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formCoordinata" v-model="validFormCoordinata">
            <v-container fluid>
              <v-layout row wrap>
                <v-flex xs12 lg12>
                  <v-text-field
                    label="Descrizione*"
                    v-model="coordinata.descrizione"
                    :rules="[$rules.required, $rules.maxlength(100)]"
                    name="descrizione"
                    hint="Es: Banca Intesa" />
                  <v-text-field
                    label="Modalità di pagamento*"
                    v-model="coordinata.modalita_pagamento"
                    name="modalita_pagamento"
                    :rules="[$rules.required, $rules.maxlength(100)]"
                  />
                  <v-text-field
                    label="IBAN*"
                    v-model="coordinata.iban"
                    name="iban"
                    :rules="[$rules.required, $rules.maxlength(100)]" />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 lg6>
                  <v-btn
                    class="ml-0"
                    color="primary"
                    id="salvaCoordinata"
                    :disabled="!validFormCoordinata"
                    @click="save">
                    Salva
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- conferma elimina coordinata -->
    <v-dialog v-model="eliminaCoordinata" persistent max-width="250" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="headline">Sicuro?</v-card-title>
        <v-card-text>Stai per cancellare la coordinata <strong>{{ coordinata.descrizione }}</strong>.</v-card-text>
        <v-card-actions>
          <v-btn @click.native="eliminaCoordinata = false">Annulla</v-btn>
          <v-btn color="error" @click.native="removeCoordinata">Elimina</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip left>
      <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click.stop="openCoordinateDialog()">
        <v-icon color="white">add</v-icon>
      </v-btn>
      <span>Nuova coordinata bancaria</span>
    </v-tooltip>
  </section>
</template>
<script>
import { $EventBus } from '@/eventBus'
import _clone from 'lodash/clone'
export default {
  data: () => ({
    cliente: null,
    coordinate: [],
    coordinata: {},
    showCoordinata: false,
    eliminaCoordinata: false,
    validFormCoordinata: false
  }),
  computed: {
    modalTitle () {
      return this.coordinata._id ? 'Modifica coordinata bancaria' : 'Nuova coordinata bancaria'
    }
  },
  methods: {
    getCoordinate () {
      this.$plsqlReadAll('banca', { cliente: this.cliente })
        .then(response => {
          this.coordinate = response.data
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura coordinate' })
        })
    },
    save () {
      if (this.$refs.formCoordinata.validate()) {
        this.$plsqlSave('banca', this.coordinata)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Coordinata salvata'
            })
            this.showCoordinata = false
            this.getCoordinate()
          })
          .catch(err => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Errore salvataggio coordinata' })
          })
      }
    },
    removeCoordinata () {
      this.$plsqlDelete('banca', this.coordinata._id)
        .then(response => {
          $EventBus.$emit('message', { type: 'success', text: 'Coordinata eliminata' })
          this.eliminaCoordinata = false
          this.getCoordinate()
        })
        .catch(err => {
          if (err.data.errCode === 'ORA-02292') {
            $EventBus.$emit('message', { type: 'error', text: 'Non è possibile cancellare la coordinata perché usata' })
          } else {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          }
          this.eliminaCoordinata = false
        })
    },
    openCoordinateDialog (coordinata) {
      this.coordinata = coordinata ? _clone(coordinata) : { cliente_id: this.cliente }
      if (!this.eliminaCoordinata) {
        this.showCoordinata = true
        if (!coordinata) this.$refs.formCoordinata.reset()
      }
    }
  },
  mounted () {
    this.cliente = this.$route.params.id
    this.getCoordinate()
  }
}
</script>
